import React from 'react';
import './App.css';
import background from "./me.png";
function App() {
    return (
        <div className="wrapper">
            <header>
                
                <div className="logo-container">
                    <span className="logo" style={{ backgroundImage: `url(${background})` }}></span>
                </div>
            </header>
            <div className="author-container"><h1>William Rose</h1></div>
            <main>
                <div className="content">
                    <p>Husband to Laura, K-9s' human, a finder of solutions, a brother, an uncle, a friend.</p>
                    <hr />
                    <h3>Professional</h3>
                    <ul className="fa-ul">
                        <li className="far fa-paperclip"><a href="/resume.pdf">Resume</a></li>
                        <br />
                    </ul>
                    <h3>Virtual Existence</h3>
                    <ul className="fa-ul">
                        <li className="far fa-envelope"><a href="mailto:williamtrose@gmail.com">williamtrose@gmail.com</a></li>
                        <br />
                        <li className="fab fa-linkedin-in"><a href="http://www.linkedin.com/in/williamtrose">LinkedIn</a></li>
                        <br />
                        <li className="fab fa-instagram"><a href="https://www.instagram.com/williamtrose/">#williamtrose</a></li>
                    </ul>
                    <hr/>  
                </div>
            </main>
        </div>
    );
}

export default App;